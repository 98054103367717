import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Business Law Mediation - Frank Goldman PC - Websuasion"
        ogDescription="Frank G. Goldman, P.C. provides strategic legal guidance to businesses, their owners, executives, officers, and managers in a wide variety of legal disciplines."
        image="/images/podcast/season-one/frank-g-goldman-pc.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Business Law and Mediation with Frank G. Goldman, P.C.</h1>
						<p>Frank provides strategic legal guidance to businesses, their owners, executives, officers, and managers in a wide variety of legal disciplines.</p>
						<p>In this <strong>third episode</strong> of Websuasion Conversation, we discuss contracts, operating agreements, the legal needs of physician groups, hiring issues, and the difference between arbitration and mediation.</p>
		        <p>
		          <QuickLinkButton
		            content={"Frank G. Goldman, P.C."}
		            url={"https://fggpc.com/"}
		            icon={"linkify"}
		          />
		        </p>
		        <p>
		          <QuickLinkButton
		            content={"Atlanta Business Alliance"}
		            url={"https://atlantabusinessalliance.com"}
		            icon={"linkify"}
		          />
		        </p>
					</div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/season-one/frank-g-goldman-pc.jpg" className="rounded-square" alt="Business Law and Mediation with Frank G. Goldman, P.C." width="300" />
					</div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        text={
	          <div>
							<h2>Listen Now</h2>
						  <AudioPlayer
						    src="https://websuasion-conversation.s3.amazonaws.com/WebsuasionConversationEpisode003-FrankGoldman.mp3"
						  />
							<h3>Subscribe to the podcast</h3>
							<p>
								<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank" rel="noreferrer">
									<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" width="50" />
								</a>
								<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank" rel="noreferrer">
									<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" width="50" />
								</a>
							</p>
							<p>
								Podcast RSS feed:<br /><br />

								<span className="red">https://websuasion.com/podcast.xml</span>
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>From Missile Command To Law</h2>
							<p>
								Frank tells us about his early days as an Air Force Missile Launch Officer on the Minuteman-III and how that prepared him to deal with stress in his practice.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/AwIqNTPlBgU'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Contract versus Litigation</h2>
							<p>
								Frank discusses how he’s shifted from litigation to being a general counsel for clients.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/xRQoc2OutvA'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Starting A Practice</h2>
							<p>
								Frank Goldman talks about his past with the U.S. Department of the Treasury and in corporate law before starting his practice in 2006.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/Qbfp6y1J3oI'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Being Risk Averse</h2>
							<p>
								Frank talks about networking and the Atlanta Business Alliance, on which he will be serving as president this term.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/2JA3HHMgCyQ'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Networking Groups</h2>
							<p>
								Frank talks about networking and the Atlanta Business Alliance, on which he will be serving as president this term.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/u72NfHZ5iaU'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Legal Needs of New Businesses</h2>
							<p>
								Frank discusses how he guides new businesses and startups through legal requirements.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/2flp1l_SsEg'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Transactional Law</h2>
							<p>
								Frank gets into his focus on transactional law for his clients.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/2XSwo48XkiA'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Operating Agreements</h2>
							<p>
								Frank speaks to the need for businesses to have an operating agreement in place.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/hX7v0Njg2mw'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>When You Need A Contract</h2>
							<p>
								Frank talks about what is and isn’t important regarding contracts.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/5tQ4Tkno9wo'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Legal For Physician Groups</h2>
							<p>
								Frank tells us about working with physician groups and how the stark statute affects how practices treat referrals.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/8m3zhOUCyqU'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Hiring Decisions</h2>
							<p>
								Frank talks about hiring staff.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/MwsQMxJ8RjY'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Arbitration and Mediation</h2>
							<p>
								Frank tells us the difference between Arbitration and Mediation.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/XZCQuRwvsic'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Arbitration With Outsourcing</h2>
							<p>
								Frank talks about recourse and arbitration when dealing with outsourcing overseas.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/0ybJQ0p3iy4'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h3>Privacy Policy and SSL Certificate</h3>
							<p>
								Today's homework is simple. Take a look at your website and answer two questions. First, do you have a privacy policy updated for the General Data Protection Regulation in place on your website? Second, does your website have an SSL certificate installed? Both are easy and inexpensive to correct, but very important.
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/podcast/season-one/privacy-ssl.jpg" className="rounded-image" alt="Content Marketing" width="620" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
